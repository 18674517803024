import { Component, Host, h, Element, State } from '@stencil/core';

@Component({
  tag: 'x-ellipsis',
  styleUrl: 'x-ellipsis.sass',
})
export class XEllipsis {
  @Element() el: HTMLElement;

  @State() value: string;

  connectedCallback() {
    const { childNodes } = this.el;

    for (let i = 0; i < childNodes.length; i++) {
      const node = childNodes[i] as any;

      if (node.nodeType === Node.TEXT_NODE) {
        this.value = node.wholeText.trim();
      }
    }
  }

  render() {
    return (
      <Host title={this.value}>
        <span>
          <slot></slot>
        </span>
      </Host>
    );
  }
}
