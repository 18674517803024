x-ellipsis
  display: block
  overflow: hidden
  position: relative
  max-height: 18px
  word-break: break-all
  span
    color: transparent
  &:after
    content: attr(title)
    position: absolute
    top: 0
    left: 0
    right: 0
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
